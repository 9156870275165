import {useState, useEffect} from 'react';

function md5(inputString) {
	var hc="0123456789abcdef";
	function rh(n) {var j,s="";for(j=0;j<=3;j++) s+=hc.charAt((n>>(j*8+4))&0x0F)+hc.charAt((n>>(j*8))&0x0F);return s;}
	function ad(x,y) {var l=(x&0xFFFF)+(y&0xFFFF);var m=(x>>16)+(y>>16)+(l>>16);return (m<<16)|(l&0xFFFF);}
	function rl(n,c)            {return (n<<c)|(n>>>(32-c));}
	function cm(q,a,b,x,s,t)    {return ad(rl(ad(ad(a,q),ad(x,t)),s),b);}
	function ff(a,b,c,d,x,s,t)  {return cm((b&c)|((~b)&d),a,b,x,s,t);}
	function gg(a,b,c,d,x,s,t)  {return cm((b&d)|(c&(~d)),a,b,x,s,t);}
	function hh(a,b,c,d,x,s,t)  {return cm(b^c^d,a,b,x,s,t);}
	function ii(a,b,c,d,x,s,t)  {return cm(c^(b|(~d)),a,b,x,s,t);}
	function sb(x) {
		var i;var nblk=((x.length+8)>>6)+1;var blks=new Array(nblk*16);for(i=0;i<nblk*16;i++) blks[i]=0;
		for(i=0;i<x.length;i++) blks[i>>2]|=x.charCodeAt(i)<<((i%4)*8);
		blks[i>>2]|=0x80<<((i%4)*8);blks[nblk*16-2]=x.length*8;return blks;
	}
	var i,x=sb(""+inputString),a=1732584193,b=-271733879,c=-1732584194,d=271733878,olda,oldb,oldc,oldd;
	for(i=0;i<x.length;i+=16) {olda=a;oldb=b;oldc=c;oldd=d;
		a=ff(a,b,c,d,x[i+ 0], 7, -680876936);d=ff(d,a,b,c,x[i+ 1],12, -389564586);c=ff(c,d,a,b,x[i+ 2],17,  606105819);
		b=ff(b,c,d,a,x[i+ 3],22,-1044525330);a=ff(a,b,c,d,x[i+ 4], 7, -176418897);d=ff(d,a,b,c,x[i+ 5],12, 1200080426);
		c=ff(c,d,a,b,x[i+ 6],17,-1473231341);b=ff(b,c,d,a,x[i+ 7],22,  -45705983);a=ff(a,b,c,d,x[i+ 8], 7, 1770035416);
		d=ff(d,a,b,c,x[i+ 9],12,-1958414417);c=ff(c,d,a,b,x[i+10],17,     -42063);b=ff(b,c,d,a,x[i+11],22,-1990404162);
		a=ff(a,b,c,d,x[i+12], 7, 1804603682);d=ff(d,a,b,c,x[i+13],12,  -40341101);c=ff(c,d,a,b,x[i+14],17,-1502002290);
		b=ff(b,c,d,a,x[i+15],22, 1236535329);a=gg(a,b,c,d,x[i+ 1], 5, -165796510);d=gg(d,a,b,c,x[i+ 6], 9,-1069501632);
		c=gg(c,d,a,b,x[i+11],14,  643717713);b=gg(b,c,d,a,x[i+ 0],20, -373897302);a=gg(a,b,c,d,x[i+ 5], 5, -701558691);
		d=gg(d,a,b,c,x[i+10], 9,   38016083);c=gg(c,d,a,b,x[i+15],14, -660478335);b=gg(b,c,d,a,x[i+ 4],20, -405537848);
		a=gg(a,b,c,d,x[i+ 9], 5,  568446438);d=gg(d,a,b,c,x[i+14], 9,-1019803690);c=gg(c,d,a,b,x[i+ 3],14, -187363961);
		b=gg(b,c,d,a,x[i+ 8],20, 1163531501);a=gg(a,b,c,d,x[i+13], 5,-1444681467);d=gg(d,a,b,c,x[i+ 2], 9,  -51403784);
		c=gg(c,d,a,b,x[i+ 7],14, 1735328473);b=gg(b,c,d,a,x[i+12],20,-1926607734);a=hh(a,b,c,d,x[i+ 5], 4,    -378558);
		d=hh(d,a,b,c,x[i+ 8],11,-2022574463);c=hh(c,d,a,b,x[i+11],16, 1839030562);b=hh(b,c,d,a,x[i+14],23,  -35309556);
		a=hh(a,b,c,d,x[i+ 1], 4,-1530992060);d=hh(d,a,b,c,x[i+ 4],11, 1272893353);c=hh(c,d,a,b,x[i+ 7],16, -155497632);
		b=hh(b,c,d,a,x[i+10],23,-1094730640);a=hh(a,b,c,d,x[i+13], 4,  681279174);d=hh(d,a,b,c,x[i+ 0],11, -358537222);
		c=hh(c,d,a,b,x[i+ 3],16, -722521979);b=hh(b,c,d,a,x[i+ 6],23,   76029189);a=hh(a,b,c,d,x[i+ 9], 4, -640364487);
		d=hh(d,a,b,c,x[i+12],11, -421815835);c=hh(c,d,a,b,x[i+15],16,  530742520);b=hh(b,c,d,a,x[i+ 2],23, -995338651);
		a=ii(a,b,c,d,x[i+ 0], 6, -198630844);d=ii(d,a,b,c,x[i+ 7],10, 1126891415);c=ii(c,d,a,b,x[i+14],15,-1416354905);
		b=ii(b,c,d,a,x[i+ 5],21,  -57434055);a=ii(a,b,c,d,x[i+12], 6, 1700485571);d=ii(d,a,b,c,x[i+ 3],10,-1894986606);
		c=ii(c,d,a,b,x[i+10],15,   -1051523);b=ii(b,c,d,a,x[i+ 1],21,-2054922799);a=ii(a,b,c,d,x[i+ 8], 6, 1873313359);
		d=ii(d,a,b,c,x[i+15],10,  -30611744);c=ii(c,d,a,b,x[i+ 6],15,-1560198380);b=ii(b,c,d,a,x[i+13],21, 1309151649);
		a=ii(a,b,c,d,x[i+ 4], 6, -145523070);d=ii(d,a,b,c,x[i+11],10,-1120210379);c=ii(c,d,a,b,x[i+ 2],15,  718787259);
		b=ii(b,c,d,a,x[i+ 9],21, -343485551);a=ad(a,olda);b=ad(b,oldb);c=ad(c,oldc);d=ad(d,oldd);
	}
	return rh(a)+rh(b)+rh(c)+rh(d);
}

const STATUS = {
	INITIAL: 1,
	1: 'INITIAL',
	LOADING: 2,
	2: 'LOADING',
	LOADED: 3,
	3: 'LOADED',
	FAIL: 4,
	4: 'FAIL',
	SSR: 5,
	5: 'SSR',
	CACHE: 6,
	6: 'CACHE',
}

const cache = {};

const isSSR = (typeof window === 'undefined');

const useLiveData = ({url, namespace, model, method, apiParams, type, liveRefresh = true, staticData, graphqlKey}) => {
	const initialState = {
		status: STATUS.INITIAL,
		statusText: STATUS[STATUS.INITIAL],
	}
	if (staticData) {
		let keys = graphqlKey;
		if (!!graphqlKey === false && !!type !== false)keys = [`all${type}`];
		if (!Array.isArray(keys))keys = keys.split(',').map(item => item.trim()).filter(item => item);
		initialState.response = {
			data: [],
		};
		for (const key of keys) {
			if (staticData[key] !== undefined)staticData[key].edges.forEach(({node}) => initialState.response.data.push({...node}));
		}
	}
	if (isSSR){
		initialState.status = STATUS.SSR;
		initialState.statusText = STATUS[STATUS.SSR];
	}
	const [apiResponse, setApiResponse] = useState(initialState);
	const lang = process.env.LANG||'en';
	const [isActive, setIsActive] = useState(!isSSR);
	if (process.env.LIVE_REFRESH !== undefined){
		liveRefresh = (
			'true'.localeCompare(process.env.LIVE_REFRESH, 'en', {sensitivity: 'base'}) === 0 ||
			'yes'.localeCompare(process.env.LIVE_REFRESH, 'en', {sensitivity: 'base'}) === 0 ||
			'1'.localeCompare(process.env.LIVE_REFRESH, 'en', {sensitivity: 'base'}) === 0
		);
	}
	const watchVisibility = (evt) => {
		if (!isSSR) {
			setIsActive(document.visibilityState === 'visible');
			if (document.visibilityState === 'visible') {
				updateData({
					visibilityChange: true,
				});
			}
		}
	}
	useEffect(() => {
		if (liveRefresh && !isSSR) {
			document.addEventListener('visibilitychange', watchVisibility);
		}
		return () => {
			if (liveRefresh && !isSSR) {
				document.removeEventListener('visibilitychange', watchVisibility);
			}
		}
	});

	const updateData = (props) => {
		let apiURL = process.env.GATSBY_ZMS_API_URL||'/';
		if (url !== undefined && /https?:/.test(url) === false)apiURL += url;
		if (url !== undefined && /https?:/.test(url) === true)apiURL = url;
		if (model !== undefined) {
			apiURL += `${(namespace !== undefined ? namespace+'/' : '')}${model}/${(method !== undefined ? method : 'all')}/`;
		}
		if (type) {
			apiURL += `${type.split(/(?=[A-Z])/).map(item => item.toLowerCase()).join('/')}/${(method !== undefined ? method : 'all')}/`;
		}
		if (!!apiParams === false)apiParams = {};
		const keys = Object.keys(apiParams);
		let queryString = keys.map(key => `${key}=${apiParams[key]}`).join('&');
		queryString += `${(queryString.length > 0 ? '&' : '')}_lang=${lang}&_joinLang=${lang}`;
		apiURL += (apiURL.indexOf('?') === -1 ? '?' : '&') + queryString;
		const hashKey = md5(apiURL);
		if (cache[hashKey] !== undefined) {
			setApiResponse({
				status: STATUS.CACHE,
				statusText: STATUS[STATUS.CACHE],
				response: cache[hashKey],
				...props,
			});
		} else {
			setApiResponse({
				status: STATUS.LOADING,
				statusText: STATUS[STATUS.LOADING],
				response: apiResponse.response,
				...props,
			});
		}
		fetch(apiURL)
			.then(response => {
				if (response.status !== 200)throw response.statusText;
				return response.json();
			})
			.then(json => {
				cache[hashKey] = json;
				setApiResponse({
					status: STATUS.LOADED,
					statusText: STATUS[STATUS.LOADED],
					response: json,
					...props,
				})
			})
			.catch(err => {
				setApiResponse({
					status: STATUS.FAIL,
					statusText: STATUS[STATUS.FAIL],
					response: apiResponse.response,
					...props,
				})
			});
	}

	if (!isActive)return apiResponse;
	if (apiResponse.status === STATUS.INITIAL) {
		updateData();
	}
	return apiResponse;
}

useLiveData.SSR = STATUS.SSR;
useLiveData.INITIAL = STATUS.INITIAL;
useLiveData.LOADING = STATUS.LOADING;
useLiveData.LOADED = STATUS.LOADED;
useLiveData.FAIL = STATUS.FAIL;
useLiveData.CACHE = STATUS.CACHE;

export default useLiveData;