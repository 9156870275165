import * as React from 'react';
import { Typography, Box, Menu, MenuItem, Button } from '@mui/material';
import Link from 'components/Link';
import { getColor } from 'utils/theme';

import close from 'images/close.svg';

const Dropdown = ({open, links, anchor, onClose, anchorOrigin, transformOrigin}) => {
	const overrides = {
		'& .MuiPopover-paper': {
			background: getColor('green'),
			color: getColor('neutral', 100),
			boxShadow: 'none',
			borderRadius: 0,
			border: 'none',
			top: '0px !important',
			left: '0px !important',
			width: '100%',
			maxWidth: '100%',
		},
		'& .MuiMenu-list': {
			padding: '0',
			margin: '0',
			'a': {
				textDecoration: 'none',
			}
		},
	};

	const menuStyle = {
		display: 'block',
		padding: '90px 50px 20px 50px',
	}

	const buttonStyle = {
		position: 'absolute',
		right: '38px',
		top: '38px',
	}

	const menuItemStyle = {
		marginBottom: '16px',
	}

	return (
		<Menu sx={overrides} open={open} onClose={onClose} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin}>
			<Button variant="silent" onClick={onClose} sx={buttonStyle}><img src={close} alt="Close menu"/></Button>
			<Box sx={menuStyle} className="mobileNav">
				{links.map((item, index) => <MenuItem key={index} sx={menuItemStyle}><Link href={item.href} activeClassName="active" onClick={onClose}><Typography variant="header">{item.text}</Typography></Link></MenuItem>)}
			</Box>
		</Menu>
	)
}

export default Dropdown;