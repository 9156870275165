import * as React from 'react';

export const MAIN_CONTENT_ID = 'main-content';

export default function SkipNavigationLink(){
	const style = {
		position: 'absolute',
		left: 0,
		transform: 'translateX(-100%)',
		opacity: 0,
	}
	return <a className="sr-only" style={style} href={`#${MAIN_CONTENT_ID}`}>Skip to Main Content</a>
}