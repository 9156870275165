const React = require('react');
const Layout = require('./src/components/Layout').default;

exports.wrapPageElement = ({element, props}) => {
	return (
		<Layout {...props}>{element}</Layout>
	)
}

// const observer = new PerformanceObserver((list) => {
// 	const entries = list.getEntries();
// 	entries.forEach((entry) => {
// 		console.log(entry);
// 	});
// 	// console.log(`LCP: ${lastEntry.startTime}`);
// });
// observer.observe({
// 	type: 'largest-contentful-paint',
// 	buffered: true,
// });