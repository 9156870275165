import * as React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import useLiveData from 'hooks/useLiveData';
import { Box, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Link from 'components/Link';
import theme from 'utils/theme';
import Image from 'components/Image';

import logo from 'images/logo.svg';
import wct_logo from 'images/wct-logo.svg';
import kab_logo from 'images/kab-logo.svg';

import facebook from 'images/facebook.svg';
import instagram from 'images/instagram.svg';
import twitter from 'images/twitter-logo.svg';

const Footer = (props) => {
	const data = useStaticQuery(graphql`
		query footerMenu {
		  allContentMenu (filter: {menuID: {eq: "footer"}}) {
		    edges {
		      node {
		        pages
		      }
		    }
		  }
		}
	`);
	const {response: providerResponse} = useLiveData({
		type: 'ContentMenu',
		method: 'all',
		apiParams: {
			menuID: 'footer',
		},
		staticData: data,
	});
	const links = providerResponse?.data[0]?.pages.filter(item => {
		if (item.visible === 'true' || item.visible === true)return true;
		return false;
	});

	const style = {
		...theme.palette.footer,
	}

	const addressStyle = {
		textAlign: 'center',
		[theme.breakpoints.up('sm')]: {
			textAlign: 'left',
		}
	}

	const navLinkBoxStyle = {
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '10px',
		}
	}

	const navLinkStyle = {
		[theme.breakpoints.down('sm')]: {
			marginBottom: '20px',
		},
	}

	return (
		<footer>
			<Box sx={style}>
				<Container>
					<Grid container alignItems="top">
						<Grid xs={12} md={3} lg={3} sx={navLinkBoxStyle}>
							<nav>
								<ul className="footerNav">
									{links?.map((item, index) => <li key={index}><Link href={item.href}><Typography sx={navLinkStyle} component="div" variant="footer">{item.text}</Typography></Link></li>)}
								</ul>
							</nav>
							<Typography component="div" sx={navLinkStyle} variant="footer-light">Copyright &copy; 2024</Typography>
						</Grid>
						<Grid xs={12} md={6} lg={6}>
							<Container>
								<Grid container alignItems="top" justifyContent="space-between">
									<Grid xs={12} md={4}>
										<Box display="flex" justifyContent="center" marginBottom="40px"><Link href="https://www.waltoncountyfltourism.com" target="_blank"><Image className="wct_logo" src={wct_logo} alt="Walton County Tourism" /></Link></Box>
									</Grid>
									<Grid xs={12} md={4}>
										<Box display="flex" justifyContent="center" marginBottom="40px"><Image className="kwcb_logo" src={logo} alt="Keep Walton County Beautiful" /></Box>
									</Grid>
									<Grid xs={12} md={4}>
										<Box display="flex" justifyContent="center" marginBottom="40px"><Link href="https://kab.org" target="_blank"><Image className="kab_logo" src={kab_logo} alt="Keep America Beautiful" /></Link></Box>
									</Grid>
								</Grid>
							</Container>
						</Grid>
						<Grid xs={12} md={3} lg={3}>
							<Typography sx={addressStyle} variant="footer" component="div" className="address">
								25777 US Highway 331 South<br />
								Santa Rosa Beach, Florida 32459<br />
								(800) 822-6877 or (850) 267-1216<br />
							</Typography>
							<Container disableGutters={true}>
								<Grid container alignItems="center" justifyContent="space-evenly" style={{ display: 'flex', gap: '10px' }}>
									<Grid xs={2} sm={2} style={{ display: 'flex', justifyContent: 'center' }}>
										<Link href="https://www.facebook.com/KeepWaltonCountyBeautiful/" target="_blank"><Image src={facebook} alt="Link to Facebook social media account" /></Link>
									</Grid>
									<Grid xs={2} sm={2} style={{ display: 'flex', justifyContent: 'center' }}>
										<Link href="https://www.instagram.com/keepwcbeautiful" target="_blank"><Image src={instagram} alt="Link to Instagram social media account" /></Link>
									</Grid>
									<Grid xs={2} sm={2} style={{ display: 'flex', justifyContent: 'center' }}>
										<Link href="https://x.com/KeepWCBeautiful" target="_blank"><Image src={twitter} alt="Link to X social media account" /></Link>
									</Grid>
								</Grid>
							</Container>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</footer>
	)
}

export default Footer;