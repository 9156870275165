import { createTheme } from '@mui/material/styles';
import arrow from 'images/arrow.svg';
import arrowBlack from 'images/arrow-black.svg';

export const getColor = (name, shade = 400) => {
	// TODO: Support shade names (lighter = 100, light = 300, regular = 400, bold = 700, heavy = 900)
	// TODO: Support intermediate shade values (if there's no shade match, blend the two nearest by the shade provided)
	return colors[name][shade];
}

const colors = {
	green: {
		400: '#52805d',
	},
	neutral: {
		100: '#ffffff',
		300: '#6B6B6B',
		400: '#423b3d',
		900: '#000000',
	},
	yellow: {
		400: '#f1b61e',
	},
	blue: {
		400: '#65a3ce',
	}
}
colors.primary = getColor('green');
colors.black = getColor('neutral');
colors.accent1 = getColor('yellow');
colors.accent2 = getColor('blue');

let theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 640,
			md: 1024,
			lg: 1200,
			xl: 1400
		}
	},
});

theme = createTheme({
	breakpoints: {
		values: {
			...theme.breakpoints.values,
			phone: theme.breakpoints.values.xs,
			tablet: theme.breakpoints.values.sm,
			laptop: theme.breakpoints.values.md,
			desktop: theme.breakpoints.values.lg,
		},
	},
	typography: {
		htmlFontSize: 10,
		fontSize: 16,
		fontFamily: [
			'Neutraface',
			'sans-serif',
		].join(', '),
		h1: {
			color: getColor('neutral'),
			fontSize: '4rem',
			lineHeight: 1.6,
			fontWeight: 700,
			textTransform: 'uppercase',
			marginBottom: '2rem',
			[theme.breakpoints.up('md')]: {
				fontSize: '4.5rem',
			}
		},
		h2: {
			color: getColor('neutral'),
			fontSize: '3.4rem',
			lineHeight: 1.2,
			fontWeight: 700,
			textTransform: 'uppercase',
			marginBottom: '2rem',
			[theme.breakpoints.up('md')]: {
				fontSize: '4rem',
			}
		},
		h3: {
			color: getColor('neutral'),
			fontSize: '2.8rem',
			lineHeight: 1,
			fontWeight: 700,
			marginBottom: '2rem',
			[theme.breakpoints.up('md')]: {
				fontSize: '3rem',
			}
		},
		h4: {
			color: getColor('neutral'),
			fontSize: '2rem',
			lineHeight: 1.6,
			fontWeight: 600,
			textTransform: 'uppercase',
			marginBottom: '1.6rem',
			[theme.breakpoints.up('md')]: {
				fontSize: '2.4rem',
			}
		},
		h5: {
			color: getColor('neutral'),
			fontSize: '2rem',
			lineHeight: 1,
			fontWeight: 600,
			marginBottom: '1.6rem',
			[theme.breakpoints.up('md')]: {
				fontSize: '2.2rem',
			}
		},
		h6: {
			color: getColor('neutral'),
			fontSize: '1.8rem',
			fontWeight: 600,
			marginBottom: '1.6rem',
			[theme.breakpoints.up('md')]: {
				fontSize: '2rem',
			}
		},
		body1: {
			fontSize: '1.6rem',
			[theme.breakpoints.up('md')]: {
				fontSize: '2rem',
			},
		},
		body2: {
			fontSize: '1.3rem',
			[theme.breakpoints.up('md')]: {
				fontSize: '1.8rem',
			},
		},
		nav: {
			fontSize: '1.8rem',
		}
	},
	palette: {
		primary: {
			main: colors.primary,
		},
		secondary: {
			main: '#F26E62',
		},
		background: {
			default: null,
		},
		text: {
			primary: colors.black,
		},
		header: {
			background: colors.primary,
			color: getColor('neutral', 100),
			padding: '24px 0 15px 0',
		},
		footer: {
			background: colors.primary,
			color: getColor('neutral', 100),
			padding: '50px 0',
			[theme.breakpoints.up('sm')]: {
				padding: '80px 0',
			},
		},
	},
	components: {
		MuiGrid2: {
			defaultProps: {
				spacing: {
					phone: 1,
					tablet: 2,
					laptop: 4,
				},
				columnSpacing: {
					phone: 1,
					tablet: 2,
					laptop: 4,
				},
				rowSpacing: {
					phone: 1,
					tablet: 2,
					laptop: 4,
				}
			}
		},

		MuiUseMediaQuery: {
			defaultProps: {
				noSsr: true,
			},
		},
		MuiButton: {
			defaultProps: {
				variant: 'outlined',
			},
			variants: [
				{
					props: { variant: 'outlined' },
					style: {
						color: `${getColor('neutral', 100)} !important`,
						border: `4px solid ${getColor('neutral', 100)}`,
						borderRadius: '12px',
						fontSize: '1.6rem',
						textTransform: 'uppercase',
						fontWeight: 700,
						padding: '20px',
						width: '100%',
						[theme.breakpoints.up('sm')]: {
							width: 'auto',
							fontSize: '1.8rem',
							padding: '8px 60px',
						},
						[theme.breakpoints.up('xl')]: {
							fontSize: '2rem',
							padding: '18px 86px 16px 86px',
						},
						'&:hover': {
							color: `${getColor('neutral', 900)} !important`,
							border: `4px solid ${getColor('yellow')}`,
							background: getColor('yellow'),
							borderRadius: '12px',
							'&:after': {
								filter: 'brightness(0) saturate(100%)',
							},
						},
						'&:after': {
							content: `url('${arrow}')`,
							marginLeft: '10px',
							transition: theme.transitions.create(),
						},
					},
				},
				{
					props: { variant: 'outlined-green' },
					style: {
						color: `${getColor('green')} !important`,
						border: `4px solid ${getColor('green')}`,
						borderRadius: '12px',
						fontSize: '1.6rem',
						textTransform: 'uppercase',
						fontWeight: 700,
						padding: '20px',
						width: '100%',
						[theme.breakpoints.up('sm')]: {
							width: 'auto',
							fontSize: '1.8rem',
							padding: '8px 60px',
						},
						[theme.breakpoints.up('xl')]: {
							fontSize: '2rem',
							padding: '18px 86px 16px 86px',
						},
						'&:hover': {
							color: `${getColor('neutral', 100)} !important`,
							background: getColor('green'),
							'&:after': {
								filter: 'brightness(1) saturate(100%)',
							},
						},
						'&:after': {
							content: `url('${arrow}')`,
							marginLeft: '10px',
							filter: 'brightness(0) saturate(100%) invert(47%) sepia(15%) saturate(903%) hue-rotate(82deg) brightness(92%) contrast(86%)',
							transition: theme.transitions.create(),
						},
					},
				},
				{
					props: { variant: 'green' },
					style: {
						color: `${getColor('neutral', 100)} !important`,
						background: getColor('green'),
						border: `4px solid ${getColor('green')}`,
						borderRadius: '12px',
						fontSize: '1.6rem',
						textTransform: 'uppercase',
						fontWeight: 700,
						padding: '20px',
						width: '100%',
						[theme.breakpoints.up('sm')]: {
							width: 'auto',
							fontSize: '1.8rem',
							padding: '8px 60px',
						},
						[theme.breakpoints.up('xl')]: {
							fontSize: '2rem',
							padding: '18px 86px 16px 86px',
						},
						'&:hover': {
							color: `${getColor('green')} !important`,
							background: getColor('neutral', 100),
							borderColor: getColor('green'),
							'&:after': {
								filter: 'invert(43%) sepia(30%) saturate(453%) hue-rotate(82deg) brightness(98%) contrast(90%)',
							},
						},
						'&:after': {
							content: `url('${arrow}')`,
							marginLeft: '10px',
							transition: theme.transitions.create(),
						},
					},
				}
			],
		},
		MuiBox: {
			styleOverrides: {
				root: ({ownerState, theme}) => ({
					...(ownerState.variant !== undefined && ownerState.variant.localeCompare('blue-box', 'en-US', {sensitivity: 'base'}) === 0 && {
						backgroundColor: getColor('blue'),
						color: getColor('neutral', 100),
						borderRadius: '45px',
						paddingTop: '60px',
						paddingLeft: '60px',
						paddingRight: '60px',
						paddingBottom: '80px',
					}),
				})
			},
		},
		MuiTypography: {
			variants: [
				{
					props: { variant: 'header' },
					style: {
						fontSize: '2rem',
						lineHeight: '130%',
						fontWeight: 600,
						textTransform: 'uppercase',
						textDecoration: 'none',
						'*': {
							textDecoration: 'none',
						},
						color: getColor('neutral', 100),
						[theme.breakpoints.up('md')]: {
							fontSize: '1.8rem',
						},
						[theme.breakpoints.up('xl')]: {
							fontSize: '2.2rem',
						},
					},
				},
				{
					props: { variant: 'footer' },
					style: {
						fontSize: '2.2rem',
						lineHeight: '130%',
						fontWeight: 600,
						[theme.breakpoints.up('md')]: {
							fontSize: '1.8rem',
						},
						[theme.breakpoints.up('xl')]: {
							fontSize: '2.2rem',
						},
						'a &:hover, &.active': {
							'color': getColor('yellow'),
						},
					},
				},
				{
					props: { variant: 'footer-light' },
					style: {
						fontSize: '1.6rem',
						lineHeight: '130%',
						fontWeight: 300,
						[theme.breakpoints.up('md')]: {
							fontSize: '1.4rem',
						},
						[theme.breakpoints.up('xl')]: {
							fontSize: '1.8rem',
						},
						'a &:hover, &.active': {
							'color': getColor('yellow'),
						},
					},
				},
				{
					props: { variant: 'masthead-title' },
					style: {
						fontSize: '2.8rem',
						lineHeight: '95%',
						fontWeight: 700,
						color: getColor('neutral', 100),
						textTransform: 'uppercase',
						marginBottom: '20px',
						[theme.breakpoints.up('md')]: {
							fontSize: '3.6rem',
						},
						[theme.breakpoints.up('xl')]: {
							fontSize: '4.2rem',
						},
					},
				},
				{
					props: { variant: 'masthead-body' },
					style: {
						fontSize: '1.8rem',
						lineHeight: '150%',
						fontWeight: 400,
						color: getColor('neutral', 100),
						marginBottom: '50px',
						[theme.breakpoints.up('md')]: {
							fontSize: '1.8rem',
						},
						[theme.breakpoints.up('xl')]: {
							fontSize: '2rem',
						},
					},
				},
			],
		},
		MuiContainer: {
			styleOverrides: {
				maxWidthLg: {
					[theme.breakpoints.up('xl')]: {
						maxWidth: '1400px',
					},
				},
			},
		},
		MuiCssBaseline: {
			styleOverrides: {
				':root': {
					'--swiper-pagination-color': getColor('yellow'),
					'--swiper-pagination-bullet-size': '20px',
					'--swiper-pagination-bullet-horizontal-gap': '20px',
					'--swiper-pagination-bottom': '0',
					'--swiper-pagination-bullet-border': `2px solid ${getColor('neutral')}`,
					'--swiper-pagination-bullet-inactive-opacity': 1,
					'--swiper-pagination-bullet-inactive-color': 'transparent',

					'--active-link-border-bottom': `3px solid ${getColor('yellow')}`,

					'--theme-color-blue': getColor('blue'),
					'--theme-color-green': getColor('green'),
					'--theme-color-yellow': getColor('yellow'),
					'--theme-color-black': getColor('neutral', 900),
					'--theme-color-neutral': getColor('neutral', 100),
					'--theme-color-gray': getColor('neutral', 300),
					'--theme-border-radius': '45px',
					'--theme-button-arrow': `url('${arrow}')`,
					'--theme-button-arrow-inverse': `url('${arrowBlack}')`,
					'--theme-button-transition': theme.transitions.create(),

					'--cdn-domain': process.env.CDN_DOMAIN||'',
				},
			},
		},
	},
});

export default theme;