import * as React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import useLiveData from 'hooks/useLiveData';
import { Typography, Box, Container, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Link from 'components/Link';
import theme from 'utils/theme';
import MobileMenu from 'components/MobileMenu';

import logo from 'images/logo.svg';
import menu from 'images/menu.svg';

const Header = (props) => {
	const data = useStaticQuery(graphql`
		query headerMenu {
		  allMainMenu:allContentMenu (filter: {menuID: {eq: "main"}}) {
		    edges {
		      node {
		        pages
		      }
		    }
		  }
		}
	`);
	const { response } = useLiveData({
		type: 'ContentMenu',
		method: 'all',
		apiParams: {
			menuID: 'main',
		},
		graphqlKey: 'allMainMenu',
		staticData: data,
	});

	const navigationStyle = {
		display: {
			xs: 'none',
			sm: 'block',
		}
	}

	const logoStyle = {
		textAlign: {
			xs: 'center',
			sm: 'left',
		}
	}

	// const [showOffCanvas, setShowOffCanvas] = React.useState(false);
	const [showMobileMenu, setShowMobileMenu] = React.useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);

	const closeMenu = () => {
		setShowMobileMenu(false);
		setAnchorEl(null);
	}

	const toggleMobileMenu = (evt) => {
		setAnchorEl((showMobileMenu ? null : evt.currentTarget));
		setShowMobileMenu(!showMobileMenu);
	}

	const mainLinks = response.data[0].pages.filter(item => {
		if (item.visible === 'true' || item.visible === true)return true;
		return false;
	});

	const style = {
		...theme.palette.header,
		position: 'relative',
	}

	const menuButtonStyle = {
		display: {
			xs: 'block',
			sm: 'none',
		},
		position: 'absolute',
		top: '50%',
		right: '3%',
		transform: 'translateY(-50%)',
	}

	return (
		<>
		<header>
			<Box sx={style}>
				<Button sx={menuButtonStyle} onClick={toggleMobileMenu} variant="silent"><img src={menu} alt="Open navigation menu." /></Button>
				<Container>
					<Grid container alignItems="center">
						<Grid xs={12} sm={4}>
							<Box sx={logoStyle}>
								<Link href="/" className="logo"><img src={logo} alt="" /></Link>
							</Box>
						</Grid>
						<Grid sm={8}>
							<Box sx={navigationStyle}>
								<nav className="main-nav">
									<ul className="primaryNav">
										{mainLinks.map((item, index) => <li key={index}><Link href={item.href} activeClassName="active"><Typography variant="header">{item.text}</Typography></Link></li>)}
									</ul>
								</nav>
							</Box>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</header>
		<MobileMenu open={showMobileMenu} anchor={anchorEl} onClose={closeMenu}/>
		</>
	)
}

export default Header;