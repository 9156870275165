/** @jsx jsx */
import * as React from 'react';
import PropTypes from 'prop-types';
import { Blurhash } from 'react-blurhash';
import { useStaticQuery, graphql } from 'gatsby';
// import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { css, jsx } from '@emotion/react';
import theme from 'utils/theme';
/**
 * withArtDirection function
 * 
 * Use this function as a helper to generate an art direction object to pass to the Image component's SRC attribute.
 * The object that is created is an instrisic JavaScript Object where the key's are media queries and the values are paths to load for those media queries. This function ensures that the XS breakpoint exists as the source image path.
 * Be aware that when using art direction the Image component will not automatically generate DDPX (Retina) variants.
 * 
 * @function withArtDirection
 * @param {string} image - The base image path to use for the fallback and starting XS breakpoint.
 * @param {Object} directions - Any number of directions which is represented as an Object with key=value pairs; where the key is a media query and value is the image file to load for that media query.
 * 
 * @return {Object} An art direction object which can be passed to the Image component's SRC attribute.
 * 
 * @example
 * // Use two different images between the typical mobile and desktop breakpoints
 * withArtDirection("/userfiles/photo.jpg", {
 * 	[theme.breakpoints.up('sm')]: "/userfiles/larger_photo.jpg",
 * })
 * 
 * @TOOD:
 * 	1. Clean up the object a bit so that the Image component doesn't need to handle the @media replacement.
 *  2. Test if the media query is a valid syntax
 */ 
export const withArtDirection = (image, directions) => {
	return {
		[theme.breakpoints.up('xs')]: image,
		...directions,
	}
}

/**
 * buildURL function
 * 
 * This function can take a URL, with optional URL parameters, as well as a parameters object and return only the URL with merged parameters.
 * 
 * @function buildURL
 * @param {string} url - The URL to start with.
 * @param {Object} [props] - Any number of parameters to add to the URL.
 * 
 * @return {string} A URL combined with the parameters.
 * 
 * @example
 * // Create an image URL sized to 500 width.
 * buildURL("/userfiles/photo.jpg", {
 * 	width: 500,
 * })
 * 
 * @TOOD:
 * 	1. Do some typical URL sanitizing and encoding.
 *  2. Handle widths & heights specified with control characters and named sizes (width=<500, width=>500, width=half)
 */ 
export const buildURL = (url, props = {}) => {
	let imageURLOptions = {};
	if (url.indexOf('?') !== -1) {
		url.substr(url.indexOf('?')+1).split('&').forEach((pair) => {
			pair = pair.split('=');
			imageURLOptions[pair.shift()] = pair.join('=');
		});
		url = url.substr(0, url.indexOf('?'));
	}
	if (typeof props === 'string') {
		props.split('&').forEach((pair) => {
			pair = pair.split('=');
			imageURLOptions[pair.shift()] = pair.join('=');
		});
		props = {};
	}
	let maxWidth;
	let maxHeight;
	imageURLOptions = {
		...imageURLOptions,
		...props,
	};
	for (const key in imageURLOptions) {
		if (Number.isNaN(imageURLOptions[key]))delete imageURLOptions[key];
	}
	if (imageURLOptions['max-w'])maxWidth = parseInt(imageURLOptions['max-w']);
	if (typeof imageURLOptions.width === 'string' && imageURLOptions.width.indexOf('>') !== -1){
		maxWidth = parseInt(imageURLOptions.width.substr(1));
		imageURLOptions.width = parseFloat(imageURLOptions.width.substr(1));
	}
	if (imageURLOptions['max-h'])maxHeight = parseInt(imageURLOptions['max-h']);
	if (typeof imageURLOptions.height === 'string' && imageURLOptions.height.indexOf('>') !== -1){
		maxHeight = parseInt(imageURLOptions.height.substr(1));
		imageURLOptions.height = parseFloat(imageURLOptions.height.substr(1));
	}
	if (imageURLOptions.width && typeof imageURLOptions.width === 'string' && imageURLOptions.width.indexOf('%') !== -1) { // If the width is a percentage leave it out, but set a reasonable max width
		delete imageURLOptions.width;
		if (imageURLOptions['max-w'] === undefined)imageURLOptions['max-w'] = 1920;
	}
	if (imageURLOptions.height && typeof imageURLOptions.height === 'string' && imageURLOptions.height.indexOf('%') !== -1){  // If the height is a percentage leave it out, but set a reasonable max height
		delete imageURLOptions.height;
		if (imageURLOptions['max-h'] === undefined)imageURLOptions['max-h'] = 1080;
	}
	if (imageURLOptions.width)imageURLOptions.width = Math.round(imageURLOptions.width);
	if (imageURLOptions.height)imageURLOptions.height = Math.round(imageURLOptions.height);
	if (maxWidth && imageURLOptions.width > maxWidth) {
		delete imageURLOptions['max-w'];
		delete imageURLOptions.height;
		imageURLOptions.width = `>${maxWidth}`;
	};
	if (maxHeight && imageURLOptions.height > maxHeight) {
		delete imageURLOptions['max-h'];
		delete imageURLOptions.width;
		imageURLOptions.height = `>${maxHeight}`;
	};
	const urlParams = [];
	for (const key in imageURLOptions) {
		if (!imageURLOptions.hasOwnProperty(key))continue;
		if (imageURLOptions[key] === null || imageURLOptions[key] === 'null')continue;
		urlParams.push(`${key}=${imageURLOptions[key]}`);
	}
	if (urlParams.length) {
		url += `${(url.indexOf('?') === -1 ? '?' : '')}${urlParams.join('&')}`;
	}
	return url;
}

const getRetina = (image, width, height, density = 2) => {
	if (!!image === false)return null;
	if (typeof width === 'string' && width.indexOf('%') !== -1) {
		width = image.width*(parseInt(width)/100);
	}
	if (typeof height === 'string' && height.indexOf('%') !== -1) {
		height = image.height*(parseInt(height)/100);
	}
	if (image.width < width*density && image.height < height*density)return null;
	return buildURL(image.path, {
		format: 'webp',
		width: width*density,
		height: height*density,
	});
}

/**
 * Image component
 * 
 * Use this image component to display images from the CMS in a performant manner.
 * The image component has several advanced features, but in most cases the default of SRC and ALT attributes will suffice. Images are lazy loaded by default and will use a blurhash placeholder if they are found in the graphQL.
 * In addition to loading images lazy, this component will attempt to identify the best image to use for a particular size. If the SRC property is a simple string and the image detail is known from graphQL retina media queries are automatically generated from the common DDPX sizes of 1.5, 2, 3, & 4.
 * When using an object as the SRC prop, each key is expected to be a CSS media query and the value for that key is the path to the image to load for that query.
 * The image component will also read and apply some URL parameters of the filename. For example: /userfiles/photo.jpg?width=500 will set the WIDTH property to 500 if it is not set.
 * 
 * @component Image
 * @param {Object} props - All parameters listed are properties of the props object.
 * @param {string|Object} src - An image path to load. Or, an object of multiple images keyed by media query.
 * @param {string} [alt=""] - Image alt text. Defaults to an empty string indicating a decorative only image.
 * @param {string} [caption] - The Image component produces a FIGURE element. If a caption is specified it is displayed using a FIGCAPTION element. Caption cannot be added to a background image.
 * @param {Object} [sx] - Can be used similiarly to a MUI SX property. Is passed to the emotion CSS function and will apply styles to the root and children elements.
 * @param {string} [layout] - One of the following constants IMAGE.CONSTRAINED, IMAGE.FIXED, IMAGE.FULLWIDTH, IMAGE.COVER, or IMAGE.CONTAIN. In most circumstances the default is IMAGE.CONSTRAINED which is most similar to a standard IMG element, but if WIDTH & HEIGHT are specified it will usually be FIXED or COVER.
 * @param {string} [layoutPosition] A cursor to use when requesting the next page of results. Results will be returned starting at the first after the cursor, up to the pageSize or the end of the set.
 * @param {string} [transition="all 1s"] Pass in a CSS transition property to be used when cross-fading between the blurhash and full image.
 * @param {string} [classPrefix="Image"] Use this to override the CSS class names that are applied to the various elements. Image-root, Image-pictureContainer, Image-pictureElement, Image-fallbackImage, Image-blurhash, & Image-caption.
 * @param {number} [width] The width to use to display the image. Use a number width only, no units or percentages are allowed. If the image needs to scale 100% don't specify a width.
 * @param {number} [height] The height to use to display the image, Use a number height only, no units or percentages are allowed.
 * @param {number} [aspect] The aspect ratio of the image. The default is the aspect ratio found in the graphQL data, or if unavailable by calculating the width / height.
 * @param {string} [blurhash] The default is to use the blurhash found in the graphQL data. This can be provided if that is unavailable. If an image is not known at build time, the behavior is to not use a blurhash placeholder, but just load the image lazy.
 * @param {string} [loading="lazy"] This should probably never be changed. The default is load images lazy, but this can be changed to eager to load images immediately.
 * @param {boolean} [background] Whether this is a background image. The default is false, unless the Image component has children.
 * 
 * @return {JSX.Element} The rendered image component which is a PICTURE element wraped in a FIGURE element.
 * 
 * @example
 * // Simply render an image
 * // The Image component will make sure that ?width=500 is passed to the image URL so that the requested image is only 500px wide.
 * // If the source photo is 1,000 pixels wide or more and the device has a DDPX of 2 or more a srcSet is applied to load the image at 1000px wide.
 * <Image src="/userfiles/photo.jpg" width="500" />
 * 
 * // Use art direction to load a base image for mobile devices and a different image for larger devices
 * // In this case we're using a helper function which is exported from the Image component called withArtDirection, and we're using a MUI theme to provide a media query for the small and up breakpoint.
 * <Image src={withArtDirection("/userfiles/photo.jpg", {
 * 	[theme.breakpoints.between('sm', 'xl')]: "/userfiles/larger_photo.jpg",
 * 	[theme.breakpoints.up('xl')]: "/userfiles/gigantic_photo.jpg",
 * })} />
 * 
 * // Use a caption and style the caption using the sx property
 * <Image sx={{
 * 	'.Image-caption': {
 *    fontWeight: 'bold',
 *  }
 * }} src="/userfiles/photo.jpg" caption="My first year photo" />
 * 
 * 
 * @TOOD:
 * 	1. Need to add some support for percentage widths or known widths like half, quarter, etc.
 *  2. Need to allow this to work with a FQDN image (CDN support).
 *  3. Need to use COVER if the width and height specified break the aspect ratio -- unless the layout is specified.
 *  4. Allow setting the default format returned.
 *  5. Allow specifying the different retinia factors.
 *  6. Automatically detect if the image is in the viewport and default to NOT load lazy
 */ 
const Image = (props) => {
	const data = useStaticQuery(graphql`
		query imagefiles {
		  allSystemFile(filter: {type: {eq: "image"}}) {
		    edges {
		      node {
		        path
		        width
		        height
		        aspect
		        blurhash
		      }
		    }
		  }

		  site {
		    siteMetadata {
		      cdnDomain
		    }
		  }
		}
	`);
	const [imageLoaded, setImageLoaded] = React.useState(false);
	const fallbackRef = React.useRef(null);
	const alt = props.alt||'';
	const caption = props.caption;
	const transition = props.transition||theme.transitions.create();
	const classPrefix = props.classPrefix||'Image';
	const isBackground = (props.background || !!props.children !== false);
	const userfilesCheck = /^(?<origin>[a-z]+:\/\/[^/]+)?(?<path>\/userfiles\/.*$)/;
	const cdnDomain = (props.cdn||process.env.CDN_DOMAIN||data?.site?.siteMetadata?.cdnDomain||'');
	let percentageDimensions = ((props.width && props.width.indexOf('%') !== -1) || (props.height && props.height.indexOf('%') !== -1));
	let layout = props.layout||(isBackground || percentageDimensions ? 'cover' : 'constrained'); // constrained, fixed, fullWidth, cover, contain
	let imageFile;
	let width = (props.width ? (!Number.isNaN(parseInt(props.width)) ? parseInt(props.width) : null) : null);
	let height = (props.height ? (!Number.isNaN(parseInt(props.height)) ? parseInt(props.height) : null) : null);
	let aspect = props.aspect;
	let hashString = props.blurhash;
	let loading = props.loading||'lazy';

	if (!!props.src === false && !isBackground) {
		throw new Error(`Image component cannot have a missing SRC attribute when not being used as a background.`);
	}

	let imageSrc = (typeof props.src === 'string' ? props.src : (!!props.src !== false ? props.src[theme.breakpoints.up('xs')] : ''));
	let userfilesCheckResult = userfilesCheck.exec(imageSrc);
	if (userfilesCheckResult !== null && !!cdnDomain !== false) {
		imageSrc = imageSrc.replace(cdnDomain, '');
	}

	if (percentageDimensions && props.width) {
		width = props.width;
	}
	if (percentageDimensions && props.height) {
		height = props.height;
	}

	const getImageData = (src) => {
		const imageURLOptions = {};
		if (src.indexOf('?') !== -1) {
			src.substr(src.indexOf('?')+1).split('&').forEach((pair) => {
				pair = pair.split('=');
				imageURLOptions[pair.shift()] = pair.join('=');
			});
			src = src.substr(0, src.indexOf('?'));
		}
		const imageFile = data.allSystemFile.edges.filter(({node}) => node.path === src).map(item => item.node).pop();
		if (imageFile) {
			if (width === null)width = (height !== null ? Math.round(height*imageFile.aspect) : imageFile.width);
			if (height === null)height = (width !== null ? Math.round(width/imageFile.aspect) : imageFile.height);
			aspect = (imageFile.aspect ? imageFile.aspect : (width && height && !Number.isNaN(parseInt(props.width)) && !Number.isNaN(parseInt(props.height)) ? parseInt(props.width)/parseInt(props.height) : 1));
		} else {
			if (width && height)aspect = width/height;
		}
		src = buildURL(src, {
			format: 'webp',
			width: width,
			height: height,
		});
		return {
			src,
			width,
			height,
			aspect,
			imageFile,
		}
	}

	const withCDN = (src) => {
		return (src.indexOf(cdnDomain) === -1 && src.indexOf('/userfiles/') !== -1 && /https?:\/\//.test(src) === false ? `${cdnDomain}${src}` : src);
	}

	const handleLoad = (evt) => {
		setImageLoaded(true);
	}

	React.useEffect(() => {
		if (fallbackRef.current === null)return;
		if (fallbackRef.current.complete)setImageLoaded(true);
	}, [fallbackRef]);

	const rootStyle = {
		...props.sx,
		...(percentageDimensions ? {
			width: '100%',
			height: '100%',
		} : {}),
		position: 'relative',
	}

	const childStyle = css`
		position: relative;
		z-index: 2;
	`;

	if (!!props.src === false) return (
		<div className={`${classPrefix}-root`} css={rootStyle}>
			{ props.children && 
				<div className={`${classPrefix}-childContainer`} css={childStyle}>
					{ props.children }
				</div>
			}
		</div>
	)

	if (userfilesCheckResult === null){
		const passThrough = {
			loading: 'lazy',
			...props,
		}
		delete passThrough.children;
		delete passThrough.dangerouslySetInnerHTML;
		// const gatsbyImageProps = {...props};
		// if (gatsbyImageProps.image === undefined){
		// 	if (gatsbyImageProps.layout === 'cover')gatsbyImageProps.layout = 'fullWidth'; // Gatsby Image does not support these, but these are the closest matches
		// 	if (gatsbyImageProps.layout === 'contain')gatsbyImageProps.layout = 'constrained'; // Gatsby Image does not support these, but these are the closest matches
		// 	gatsbyImageProps.image = getImage(gatsbyImageProps.src);
		// }
		return <img alt={alt} {...passThrough} />; // In the future pass this through GatsbyImage to handle static assets
	}

	const imageData = getImageData(imageSrc);
	width = imageData.width;
	height = imageData.height;
	aspect = imageData.aspect;
	imageFile = imageData.imageFile;
	hashString = imageFile?.blurhash;
	imageSrc = buildURL(imageSrc, {
		format: 'webp',
		width: width,
		height: height,
	});

	const figureStyle = {
		margin: 0,
		padding: 0,
		display: 'block',
		position: 'relative',
		...(aspect ? {aspectRatio: `${aspect}`} : {}),
		...(!percentageDimensions && (props.width || props.height || props.layout === 'fixed') ? {
			maxWidth: `${width}px`,
			...(!caption ? (height ? {maxHeight: `${height}px`} : {}) : {}),
		} : {}),
		...(percentageDimensions ? {
			width: '100%',
			height: '100%',
			aspectRatio: '1 / 1',
		} : {}),
		...(isBackground ? {
			position: 'absolute',
			width: '100%',
			height: '100%',
			zIndex: 1,
			aspectRatio: '1 / 1',
		} : {}),
	};

	const pictureContainerStyle = css`
		${(props.layout === 'fixed' ?
		`
			width: ${width}px;
			height: ${height}px;
		` :
		`
			width: 100%;
			height: 100%;
		`
		)}
		position: relative;
	`;

	const pictureStyle = css`
		opacity: ${(imageLoaded ? 1 : 0)};
		font-size: 0;
		transition: ${transition};
		${(!!imageFile !== false ? `
			position: absolute;
			width: 100%;
			height: 100%;
		` : '')};
	`;

	const fallbackImageStyle = css`
		width: 100%;
		height: 100%;
		${(layout === 'cover' || layout === 'contain' ? `object-fit: ${layout}` : '')};
		${(!!props.layoutPosition !== false ? `object-position: ${props.layoutPosition}` : '')};
	`;

	const blurStyle = css`
		opacity: ${(imageLoaded ? 0 : 1)};
		transition: ${transition};
		position: absolute;
	`;

	return (
		<div className={`${classPrefix}-root`} css={rootStyle}>
			<figure className={`${classPrefix}-figure`} css={figureStyle}>
				<div className={`${classPrefix}-pictureContainer`} css={pictureContainerStyle}>
					<picture className={`${classPrefix}-pictureElement`} css={pictureStyle}>
						{ typeof props.src === 'object' && Object.keys(props.src).map((key) => {
							const mediaTypes = ['screen', 'all', 'print'];
							let mediaQuery = key.replace('@media ', '').trim();
							// This is an awfully complicated check, but basically what it's doing is checking if any key specifies one of the known media types AND no media type is present in the current one.
							if (Object.keys(props.src).some((otherKey) => (mediaTypes.find((type) => (otherKey.replace('@media ', '').trim().indexOf(type) === 0)))) && mediaTypes.find((type) => (key.indexOf(type) === 0)) === undefined) {
								mediaQuery = `screen and ${mediaQuery}`;
							}
							let srcSet = props.src[key];
							const result = key.match(/-width:([^)+]+)/);
							if (result)srcSet += ` ${parseInt(result[1])}w`;
							return (
								<source key={key} type="image/webp" media={mediaQuery} srcSet={withCDN(srcSet)} />
							)
						})}
						{ typeof props.src === 'string' && [4, 3, 2, 1.5].map((density) => (
							getRetina(imageFile, width, height, density) && <source key={`density_${density}`} type="image/webp" srcSet={`${withCDN(getRetina(imageFile, width, height, density))} ${density}x`} media={`screen and (min-resolution: ${density}x), screen and (-webkit-min-device-pixel-ratio: ${density})`} />
						))}
						<img ref={fallbackRef} className={`${classPrefix}-fallbackImage`} css={fallbackImageStyle} width={(isBackground ? '100%' : width)} height={(isBackground ? '100%' : height)} src={withCDN(imageSrc)} alt={alt} loading={loading} onLoad={handleLoad} fetchpriority={props.priority}/>
					</picture>
					{ hashString && loading !== 'eager' && <Blurhash className={`${classPrefix}-blurhash`} css={blurStyle} hash={hashString} width="100%" height="100%" /> }
				</div>
				{ caption && !isBackground && <figcaption className={`${classPrefix}-caption`}>{caption}</figcaption> }
			</figure>
			{ props.children && 
				<div className={`${classPrefix}-childContainer`} css={childStyle}>
					{ props.children }
				</div>
			}
		</div>
	)
}

Image.propTypes = {
	sx: PropTypes.object,
	alt: PropTypes.string,
	layout: PropTypes.oneOf([Image.CONSTRAINED, Image.FIXED, Image.FULLWIDTH, Image.COVER, Image.CONTAIN]),
	layoutPosition: PropTypes.string,
	caption: PropTypes.string,
	transition: PropTypes.string,
	classPrefix: PropTypes.string,
	width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	aspect: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	blurhash: PropTypes.string,
	loading: PropTypes.string,
	src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	background: PropTypes.bool,
}

Image.CONSTRAINED = 'constrained';
Image.FIXED = 'fixed';
Image.FULLWIDTH = 'fullWidth';
Image.COVER = 'cover';
Image.CONTAIN = 'contain';

export default Image;