import * as React from 'react';

import { Link as GatsbyLink } from 'gatsby';

const Link = (props) => {
	const passThrough = {...props};
	let Component = (props.component === undefined ? GatsbyLink : props.component);
	delete passThrough.children;
	delete passThrough.external;
	delete passThrough.component;

	let external;
	if (props.external === undefined) {
		if (passThrough.href === '#')external = true;
		if (/https?:\/\//.test(passThrough.href))external = true;
	} else external = props.external;
	if (external) {
		return <a {...passThrough}>{props.children||props.text}</a>
	}
	if (passThrough.to === undefined && Component === GatsbyLink)passThrough.to = passThrough.href;
	return (
		<Component {...passThrough}>{props.children||props.text}</Component>
	)
}

export default Link;