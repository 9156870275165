import * as React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import useLiveData from 'hooks/useLiveData';
import Dropdown from 'components/Dropdown';

const MobileMenu = (props) => {
	const data = useStaticQuery(graphql`
		query headerMenu {
		  allMainMenu:allContentMenu (filter: {menuID: {eq: "main"}}) {
		    edges {
		      node {
		        pages
		      }
		    }
		  }
		}
	`);
	const { response } = useLiveData({
		type: 'ContentMenu',
		method: 'all',
		apiParams: {
			menuID: 'main',
		},
		graphqlKey: 'allMainMenu',
		staticData: data,
	});

	const links = response.data[0].pages.filter(item => {
		if (item.visible === 'true' || item.visible === true)return true;
		return false;
	});

	return <Dropdown
		links={links}
		anchorOrigin={{
			vertical: 'top',
			horizontal: 'left',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'center',
		}}
		{...props}
	/>
}

export default MobileMenu;