import * as React from "react"
import { CssBaseline, ThemeProvider } from '@mui/material';
import Header from 'components/Header';
import theme from 'utils/theme';
import SkipNavigationLink from 'components/SkipNavigationLink';
import { MAIN_CONTENT_ID } from 'components/SkipNavigationLink';
import Footer from 'components/Footer';

import 'styles/app.scss';

const Layout = ({children}) => {
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline>
				<SkipNavigationLink />
				<Header />
				<section id={MAIN_CONTENT_ID} aria-label="Start of content"></section>
				{children}
				<Footer />
			</CssBaseline>
		</ThemeProvider>
	)
}

export default Layout;